import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Routes } from "../../enums/routeEnums"
import { ReportsApi } from "../../api/nest/ReportsApi"
import { IReport } from "../../types/reports/IReport"
import DashboardLayout from "../../layouts/DashboardLayout"
import dayjs from "dayjs"


const ReportsList = () => {
   const [reports, setReports] = useState<IReport[]>([])
   const [onlyUnsolved, setOnlyUnsolved] = useState<boolean>()

   const fetchReports = () => {
      ReportsApi.getAllReports({
         skip: 0,
         limit: 20,
         solved: onlyUnsolved ? false : undefined
      }).then(setReports)
   }

   useEffect(() => {
      fetchReports()
   }, [onlyUnsolved])


   const handleMakeSolved = async (reportId: string) => {
      console.log("making report solved", reportId)

      await ReportsApi.makeReportSolved(reportId)
      fetchReports()
   }

   return (
      <DashboardLayout>
         <h3 className="text-center mt-5 mb-2">Reports</h3>

         <div className="form-check mb-5">
            <input className="form-check-input" type="checkbox" value=""
                   onChange={() => setOnlyUnsolved(prevState => !prevState)} />
            <label className="form-check-label" htmlFor="flexCheckDefault">Only unsolved</label>
         </div>

         <table className="table table-striped table-bordered text-center">
            <thead>
            <tr>
               <th scope="col">ID</th>
               <th scope="col">User</th>
               <th scope="col">Lesson</th>
               <th scope="col">Task Type</th>
               <th scope="col">Task Number</th>
               <th scope="col">Step Index</th>
               <th scope="col">Text</th>
               <th scope="col">Solved</th>
               <th scope="col">Created At</th>
               <th scope="col">Actions</th>
            </tr>
            </thead>
            <tbody>
            {reports.map((report) => (
               <tr
                  key={report._id}
                  className="my-5 border border-warning align-middle"
               >
                  <th>{report._id}</th>
                  <td>{report.user.email} - {report.user.isPremium ? 'PREMIUM' : ''}</td>
                  <th>
                     <Link
                        className="btn btn-success"
                        to={`/${Routes.TASKS}/edit/${report.lesson.tasks}`}
                     >
                        Show
                     </Link>
                  </th>
                  <td>{report.taskType}</td>
                  <td>{report.taskNumber}</td>
                  <td>{report.stepIndex}</td>
                  <td>{report.text}</td>
                  <td>{JSON.stringify(report.solved)}</td>
                  <td>{dayjs(report.createdAt).format('h:mm A, MMMM D, YYYY')}</td>
                  <td>
                     {!report.solved && <button
                        className="btn btn-danger"
                        onClick={() => handleMakeSolved(report._id)}
                     >
                        Make solved
                     </button>}
                  </td>
               </tr>
            ))}
            </tbody>
         </table>
      </DashboardLayout>
   )
}

export default ReportsList
